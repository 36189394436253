// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

export const javascript: Topic = {
  topic: 'Javascript',
  level: 'Beginner',
  totalQuestions: 25,
  totalScore: 125,
  totalTime: 24000,
  questions: [
    {
      question:
        'Select your age!',
      choices: ['Children (5-11 years)', 
        'Teenagers (12-25 years)', 
        'Adults (26-45 years)', 
        'The elderly (46-65 years)'],
      type: 'MCQs',
      correctAnswers: ['The elderly (46-65 years)'],
      score: 10,
    },
    {
      question: 'Select your gender!',
      choices: ['Male', 'Female'],
      type: 'MCQs',
      correctAnswers: ['Male'],
      score: 5,
    },
    {
      question: 'What is your favorite topic?',
      choices: ['languages', 'programming'],  
      type: 'MCQs',
      correctAnswers: ['languages'],
      score: 10,
    },
    {
      question:
        'I am...',
      choices: ['Neat, structured clothing, possibly wearing glasses. My outfit could include a button-up shirt, slacks, and sensible shoes. I might carry a briefcase or a notebook', 
        'More creative and relaxed attire, perhaps with bright colors, patterns, or an artistic flair. I might wear comfortable clothing, like a flowy dress, jeans, or even have paint stains on My outfit.', ],
      type: 'MCQs',
      correctAnswers: ['More creative and relaxed attire, perhaps with bright colors, patterns, or an artistic flair. I might wear comfortable clothing, like a flowy dress, jeans, or even have paint stains on My outfit.'],
      score: 10,
    },
    {
      question: 'I am...',
      choices: ['Logical, analytical, detail-oriented, and organized. This character is focused on facts, data, and solving problems in a methodical way.', 
        'Creative, intuitive, imaginative, and emotional. This character is more focused on big-picture thinking, creativity, and expressing emotions.', ],
      type: 'MCQs',
      correctAnswers: ['Creative, intuitive, imaginative, and emotional. This character is more focused on big-picture thinking, creativity, and expressing emotions.'],
      score: 10,
    },
    {
      question: 'I am...',
      choices: ['Prefers structure and routine. I might be seen planning, making lists, working with numbers, or engaging in tasks that require precision and attention to detail.', 
        'Prefers flexibility and spontaneity. I might be seen daydreaming, doodling, playing music, or engaging in creative activities like painting or writing.', ],
      type: 'MCQs',
      correctAnswers: ['Prefers flexibility and spontaneity. I might be seen daydreaming, doodling, playing music, or engaging in creative activities like painting or writing.'],
      score: 10,
    },
    {
      question: 'I am...',
      choices: ['Workspace is likely very organized, with everything in its place, perhaps a calendar on the wall, and a clean desk with a computer or calculator.', 
        'Workspace might be more chaotic, with art supplies, sketches, or musical instruments scattered around. There might be inspirational quotes or artwork on the walls, and a general sense of organized chaos.', ],
      type: 'MCQs',
      correctAnswers: ['Workspace might be more chaotic, with art supplies, sketches, or musical instruments scattered around. There might be inspirational quotes or artwork on the walls, and a general sense of organized chaos.'],
      score: 10,
    },
    {
      question:
        'I Wanna...',
      choices: ['Practice my skills', 
        'Get Professional jobs', ],
      type: 'MCQs',
      correctAnswers: ['Get Professional jobs'],
      score: 10,
    },
    {
      question: 'I wanna...',
      choices: ['Problem-Solving Skills', 
        'Career Opportunities.',
        'Creativity and Innovation.', 
        'Understanding Technology and Communication Skills', 
        ],
      type: 'MCQs',
      correctAnswers: ['Understanding Technology and Communication Skills'],
      score: 5,
    },
    {
      question: 'I am into...',
      choices: [
        'Office Jobs (e.g., Administrative Assistant, HR Manager, Accountant)', 
        'Healthcare Jobs (e.g., Doctor, Nurse, Pharmacist', 
        'Creative Jobs (e.g., Graphic Designer, Writer, Musician)', 
        "Education Jobs (e.g., Teacher, Professor, School Administrator)",
        'Tech Jobs (e.g., Software Developer, Data Analyst, IT Specialist)',
        'Sales and Marketing Jobs (e.g., Sales Representative, Marketing Manager, Public Relations Specialist)',
        'Industy Jobs (e.g., Engineer, Mechanic, Construction Worker)',
        'Trades and Manual Labor Jobs (e.g., Electrician, Plumber, Carpenter)',
        'Entrepreneurship and Business Ownership',
        'Freelancing and Remote Work',
      ],
      type: 'MCQs',
      correctAnswers: ['Freelancing and Remote Work'],
      score: 10,
    },
    {
      question: '...best time for me to learn code or language',
      choices: ['Morning', 'Midday', 'Afternoon', 'Evening', 'Night'],
      type: 'MCQs',
      correctAnswers: ['Morning'],
      score: 10,
    },
    {
      question:
        'How long do you work on coding or language in a day?',
      choices: ['Less than 1 hour', '1-2 hours', '2-4 hours', '4-6 hours', 'More than 6 hours'],
      type: 'MCQs',
      correctAnswers: ['2-4 hours'],
      score: 10,
    },
    {
      question: 'What is your Coding or Language level?',
      choices: ['Beginner', 'Intermediate', 'Advanced'],
      type: 'MCQs',
      correctAnswers: ['Beginner'],
      score: 5,
    },
    {
      question:
        'I am...',
      choices: [
        'Observant: I notice details that others might overlook, picking up on subtle visual cues and patterns in My environment.',
        'Creative: I have a strong sense of aesthetics and often express themselves through art, design, or other visual mediums.',
        'Organized: I prefer to organize information visually, using charts, diagrams, and color-coding systems to keep track of tasks and ideas.',
        'Imaginative: My mind is always buzzing with images and visual concepts, often daydreaming or visualizing scenarios before taking action.',
      ],
      type: 'MCQs',
      correctAnswers: ['Imaginative: My mind is always buzzing with images and visual concepts, often daydreaming or visualizing scenarios before taking action.'],
      score: 10,
    },
    {
      question:
        'I am...',
      choices: [
        'Learning Style: I prefer to learn by watching videos, looking at diagrams, and creating visual summaries of information. I might struggle with long blocks of text and instead, break down information into more digestible visual formats.',
        'Problem-Solving: I use mind maps, flowcharts, or sketches to solve problems, seeing connections and relationships between ideas more easily when I am  visualized.',
        'Communication: When explaining something, I often draw diagrams or use visual metaphors to make My point clear. I may rely heavily on visual aids like slideshows or videos during presentations.',
        'Note-Taking: My notes are likely filled with doodles, diagrams, and color-coded sections. I might use apps or tools that allow them to create visual notes digitally.',
      ],
      type: 'MCQs',
      correctAnswers: ['Note-Taking: My notes are likely filled with doodles, diagrams, and color-coded sections. I might use apps or tools that allow them to create visual notes digitally.'],
      score: 10,
    },
    {
      question:
        'I am...',
      choices: [
        'Creative Space: My environment is likely filled with visual stimuli – art on the walls, colorful decor, and perhaps a vision board displaying My goals and inspirations.',
        'Digital Tools: I use digital tools like graphic design software, visual note-taking apps, or project management tools that support visual organization, such as Trello or Canva.',
      ],
      type: 'MCQs',
      correctAnswers: ['Digital Tools: I use digital tools like graphic design software, visual note-taking apps, or project management tools that support visual organization, such as Trello or Canva.'],
      score: 10,
    },
    {
      question:
        'I am...',
      choices: [
        'Art and Design: I enjoy drawing, painting, photography, or graphic design. These hobbies allow them to explore and express My visual creativity.',
        'Puzzles and Games: I might enjoy visual puzzles, such as jigsaw puzzles, mazes, or games that require spatial awareness and visual strategy.',
        'Photography: Capturing moments visually is a key interest, whether through photography, videography, or even curating images on social media platforms like Instagram.',
      ],
      type: 'MCQs',
      correctAnswers: ['Photography: Capturing moments visually is a key interest, whether through photography, videography, or even curating images on social media platforms like Instagram.'],
      score: 10,
    },
    {
      question:
        'I am...',
      choices: [
        'Active: I have high energy levels and prefer to be on the move, often finding it difficult to sit still for long periods.',
        'Practical: I am hands-on learners who prefer to learn by doing rather than by reading or listening. I excel in tasks that require physical interaction or manipulation of objects.',
        'Tactile: I enjoy working with My hands, whether it’s building something, crafting, or engaging in physical tasks. I often express themselves through physical activities.',
        'Adventurous: I am  often eager to try new things and explore the world around them, learning through experience and exploration.',
      ],
      type: 'MCQs',
      correctAnswers: ['Adventurous: I am  often eager to try new things and explore the world around them, learning through experience and exploration.'],
      score: 10,
    },
    {
      question:
        'I am...',
      choices: [
        'Clothing: The character might wear comfortable, practical clothing that allows for easy movement, like athletic wear, cargo pants, or sneakers. My outfit might have a utilitarian feel, reflecting My preference for action and movement.',
        'Accessories: I could carry a backpack or a toolkit, always prepared for hands-on tasks or physical activities. I might also have a fitness tracker or a smartwatch, showing My focus on staying active.',
        'Physical Condition: I am  likely to have a fit, agile appearance, reflecting My active lifestyle. I might have a confident, grounded posture, always ready to spring into action.',
      ],
      type: 'MCQs',
      correctAnswers: ['Physical Condition: I am  likely to have a fit, agile appearance, reflecting My active lifestyle. I might have a confident, grounded posture, always ready to spring into action.'],
      score: 10,
    },
    {
      question:
        'I am...',
      choices: [
        'Role-Playing and Simulations: I excel in learning environments that use role-playing, simulations, or physical demonstrations. I learn by being actively involved in the learning process.',
        'Physical Memorization: I might use physical movement to help remember information, such as pacing while studying or using hand gestures to reinforce learning.',
      ],
      type: 'MCQs',
      correctAnswers: ['Physical Memorization: I might use physical movement to help remember information, such as pacing while studying or using hand gestures to reinforce learning.'],
      score: 10,
    },
    {
      question:
        'I am...',
      choices: [
        'Sports and Physical Activities: I enjoy sports, dance, martial arts, or any activity that gets them moving. Physical challenges and competitions might be particularly appealing.',
        'DIY and Crafting: I love to build, create, and fix things. DIY projects, woodworking, crafting, or any activity that involves working with My hands are likely hobbies..',
        'Adventure and Travel: I enjoy exploring new places and trying new physical activities, whether it’s hiking, rock climbing, or traveling to experience new cultures.',
        'Cooking and Culinary Arts: Cooking can be a favorite activity, as it involves using the hands, experimenting with ingredients, and creating something tangible.'
      ],
      type: 'MCQs',
      correctAnswers: ['Physical Memorization: I might use physical movement to help remember information, such as pacing while studying or using hand gestures to reinforce learning.'],
      score: 10,
    },
    {
      question:
        'I am...',
      choices: [
        'Attentive Listener: I have a keen ability to listen and process auditory information. I pay close attention to sounds, voices, and tones, and can often recall what I’ve heard with high accuracy.',
        'Verbal Communicator: I enjoy discussing ideas and concepts out loud, often thinking through problems by talking them through. I might prefer group discussions and verbal explanations.',
        'Memory for Sounds: I have a strong memory for verbal information, such as facts, instructions, and stories. I might recall songs, conversations, or lectures more easily than visual or written information.',
        'Reflective: I often reflect on what I’ve heard, taking time to process information through internal dialogue or verbal contemplation.'
      ],
      type: 'MCQs',
      correctAnswers: ['Physical Memorization: I might use physical movement to help remember information, such as pacing while studying or using hand gestures to reinforce learning.'],
      score: 10,
    },
    {
      question:
        'I am...',
      choices: [
        'Clothing: The character might wear stylish, comfortable clothing that reflects My thoughtful and introspective nature. Accessories like headphones or earbuds might be a constant companion.',
        'Accessories: I could carry a digital recorder, notebooks filled with audio notes, or a podcast app on My smartphone. I might also have a playlist or a collection of audiobooks always ready.',
        'Physical Traits: I may have a calm and attentive demeanor, often seen listening carefully during conversations or lectures.',
      ],
      type: 'MCQs',
      correctAnswers: ['Physical Memorization: I might use physical movement to help remember information, such as pacing while studying or using hand gestures to reinforce learning.'],
      score: 10,
    },
    {
      question:
        'I am...',
      choices: [
        'Audio-Friendly Space: My environment might include a comfortable area for listening to audio materials, such as a cozy chair with good sound quality or a dedicated space for recording and listening.',
        'Background Sounds: I might enjoy having background music or ambient sounds while working, as long as it helps them focus rather than distracts them.',
      ],
      type: 'MCQs',
      correctAnswers: ['Physical Memorization: I might use physical movement to help remember information, such as pacing while studying or using hand gestures to reinforce learning.'],
      score: 10,
    },
    {
      question:
        'I am...',
      choices: [
        'Music and Podcasts: I enjoy listening to music, radio shows, or podcasts, finding pleasure and learning in auditory experiences. I might also be involved in creating or performing music.',
        'Public Speaking and Debating: I might participate in activities like public speaking, debating, or storytelling, where verbal skills and auditory processing are key.',
        'Audio Books: I often listen to audiobooks and enjoy the storytelling aspect of literature, preferring auditory over visual reading experiences.',
        'Language Learning: I might use language learning apps that emphasize listening and speaking skills, enjoying the auditory aspect of acquiring new languages.'
      ],
      type: 'MCQs',
      correctAnswers: ['Language Learning: I might use language learning apps that emphasize listening and speaking skills, enjoying the auditory aspect of acquiring new languages.'],
      score: 10,
    },
    
  ],
}
